"use client";

import { Paragraph } from "@/components/ui/texts/paragraph";
import { getLocalStorage, setLocalStorage } from "@/lib/utils";
import Link from "next/link";
import { useEffect, useState } from "react";
import Script from "next/script";

declare global {
    interface Window {
        gtag?: (...args: any[]) => void;
    }
}

export default function CookieBanner() {

    const [cookieConsent, setCookieConsent] = useState<boolean | undefined>(false);

    useEffect(() => {
        const storedCookieConsent: boolean | undefined = getLocalStorage("cookie_consent", undefined);

        setCookieConsent(storedCookieConsent);

        if (storedCookieConsent === true && window.gtag) {
            window.gtag('consent', 'update', {
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
            });
        }
    }, [setCookieConsent]);

    const handleCookieConsent = (consent: boolean) => {
        setCookieConsent(consent);
        setLocalStorage("cookie_consent", consent);

        if (consent && window.gtag) {
            window.gtag('consent', 'update', {
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
            });
        }
    }

    if (cookieConsent === undefined ) {
        return (
            <>
                <Script 
                    id="gtm-consent-script"
                    dangerouslySetInnerHTML={{
                        __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('consent', 'default', {
                            'ad_storage': 'denied',
                            'ad_user_data': 'denied',
                            'ad_personalization': 'denied',
                            'analytics_storage': 'denied'
                        });
                        `
                    }} />
                <Script
                    src={`https://www.googletagmanager.com/gtag/js?id=GTM-WJSM34NX`}
                    strategy="afterInteractive"
                />
                <Script
                    id="gtm-script"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-WJSM34NX');
                        `
                    }}
                />
                <div
                    className={`${cookieConsent === true
                        ? "hidden"
                        : "flex  flex-col fixed inset-x-0 bottom-0 z-20  justify-between gap-x-8 gap-y-4 bg-secondary-200 p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8 xs:block"
                        }`}
                >
                    <Paragraph className="max-w-4xl text-sm leading-6 text-gray-900">
                        This website uses cookies to enhance your browsing experience, analyze
                        site traffic, and serve better user experiences. By continuing to use
                        this site, you consent to our use of cookies. Learn more in our{" "}
                        <Link className="font-semibold text-[#2C92DD]" href="/terms" target="_blank" rel="noopener noreferrer">
                            cookie policy
                        </Link>
                    </Paragraph>


                    <div className="flex gap-2">
                        <div className="mr-16 flex flex-none items-center gap-x-5">
                            <button
                                onClick={() => handleCookieConsent(true)}
                                type="button"
                                className="rounded-md bg-grey-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-grey-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                            >
                                Accept all 🍪
                            </button>
                            <button
                                onClick={() => handleCookieConsent(false)}
                                type="button"
                                className="text-sm font-semibold leading-6 text-grey-900 hover:text-grey-800 hover:underline"
                            >
                                Reject all
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }


    return null;
}